import React, { useParams } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import { FaRegEye } from 'react-icons/fa';

import './styles.css';

import ListaProjetos from '../../assets/projetos.json';

export default function ProjetoSingle({ match }){

    const { params: { slug } } = match;

    var projeto = ListaProjetos.find((proj) => {
        return proj.slug === slug;
    });
    console.log(projeto);

    return (
        <div className="container-projetos">
            <Container>
                <h1 className="ps-page-title-text"><span className="bar"></span>PROJETOS<span className="bar"></span></h1>
            </Container>
            <Container className="projeto_single" >
                <div className="row">
                    <Col md={8} sm={6} xs={12}><img className="content-image" src={require('../../assets/projetos/' + projeto.img_url)} alt={projeto.title} /></Col>
                    <Col md={4} sm={6} xs={12}>
                        <h4>{projeto.title}</h4>
                        <p className="description">{projeto.description}</p>
                        <p className="other-info"><span>Categoria:</span> {projeto.categoria}</p>
                        <hr/>
                        <p className="other-info"><span>Cliente:</span> {projeto.cliente}</p>
                        <Link className="btn-projeto-voltar" to={"/projetos"}>Voltar</Link>
                    </Col>
                </div>
            </Container>
        </div>
    );
}