import React from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';

import Home from './pages/Home';
import Empresa from './pages/Empresa';
import Projetos from './pages/Projetos';
import ProjetoSingle from './pages/ProjetoSingle';
import Clientes from './pages/Clientes';
import Contato from './pages/Contato';
import Header from './Header';
import Footer from './Footer';

export default function Routes(){
    return (
        <div>
            <BrowserRouter>
        <Header />
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/empresa" exact component={Empresa} />
                <Route path="/projetos" exact component={Projetos} />
                <Route path="/projeto/:slug" exact component={ProjetoSingle} />
                <Route path="/clientes" exact component={Clientes} />
                <Route path="/contato" exact component={Contato} />
            </Switch>
                <Footer />
            </BrowserRouter>
        </div>
    )
}