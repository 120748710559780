import React from 'react';
import Container from 'react-bootstrap/Container';

import './styles.css';

import ListaClientes from '../../assets/clientes.json';

export default function Clientes(){

    return (
        <div className="container-clientes" >
            <Container>
                <h1 className="ps-page-title-text"><span className="bar"></span>CLIENTES<span className="bar"></span></h1>
            </Container>
            <Container>
                
                <ul className="lista_clientes">
                    {ListaClientes.map(cliente => (
                        <li className="cliente" key={cliente.id}>
                            <img src={require('../../assets/clientes' + cliente.logo)} alt={cliente.cliente} />
                        </li>
                    ))}
                </ul>
            </Container>
        </div>
    );
}