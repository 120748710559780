import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button'
import { FaRegEye } from 'react-icons/fa';

import './styles.css';

import ListaProjetos from '../../assets/projetos.json';

export default function Projetos(){

    // get data from api, won't change
    const [projetoli, setProjetoli] = useState(ListaProjetos);
    // used for displaying polls and filtering
    const [categoriaativa, setCategoriaAtiva] = useState("all" );  
    
    function showCategoria(e) {
        console.log(e.target.getAttribute("data-slug"));
        setCategoriaAtiva(e.target.getAttribute("data-slug"));
    }

    return (
        <div className="container-projetos">
            <Container>
                <h1 className="ps-page-title-text"><span className="bar"></span>PROJETOS<span className="bar"></span></h1>
            </Container>
            <Container>
                <div className="lista-projetos-btn">
                    <Button onClick={showCategoria} variant="lista-projetos" className={categoriaativa == 'all' ? 'filter-btn active' : 'filter-btn'} data-slug="all">TODOS</Button>
                    <Button onClick={showCategoria} variant="lista-projetos" className={categoriaativa == 'brindes' ? 'filter-btn active' : 'filter-btn'} data-slug="brindes">Brindes</Button>
                    <Button onClick={showCategoria} variant="lista-projetos" className={categoriaativa == 'desenvolvimento-web' ? 'filter-btn active' : 'filter-btn'} data-slug="desenvolvimento-web">Desenvolvimento web</Button>
                    <Button onClick={showCategoria} variant="lista-projetos" className={categoriaativa == 'identidade-visual' ? 'filter-btn active' : 'filter-btn'} data-slug="identidade-visual">Identidade Visual</Button>
                    <Button onClick={showCategoria} variant="lista-projetos" className={categoriaativa == 'material-de-apoio' ? 'filter-btn active' : 'filter-btn'} data-slug="material-de-apoio">Material de apoio</Button>
                    <Button onClick={showCategoria} variant="lista-projetos" className={categoriaativa == 'midias-sociais' ? 'filter-btn active' : 'filter-btn'} data-slug="midias-sociais">Mídias Digitais</Button>
                    <Button onClick={showCategoria} variant="lista-projetos" className={categoriaativa == 'papelaria-administrativa' ? 'filter-btn active' : 'filter-btn'} data-slug="papelaria-administrativa">Papelaria administrativa</Button>
                    <Button onClick={showCategoria} variant="lista-projetos" className={categoriaativa == 'producao-grafica' ? 'filter-btn active' : 'filter-btn'} data-slug="producao-grafica">Produção gráfica</Button>
                </div>
            </Container>
            <Container className="lista-projetos-galeria" >
                <ul className="lista_projetos">
                    {projetoli.map(projeto => (
                        <li className={projeto.categoria.includes(categoriaativa) || categoriaativa == 'all' ? 'projeto active' : 'projeto'} key={projeto.id} data-categoria={projeto.categoria} >
                            <Link className="projeto-link" to={"/projeto/" + projeto.slug}>
                                <div className="content-overlay"></div>
                                {/* <img src={require('../../assets/clientes' + projeto.logo)} alt={projeto.title} /> */}
                                <img className="content-image" src={require('../../assets/projetos/' + projeto.img_url)} alt={projeto.title} />
                                <div className="content-details fadeIn-bottom">
                                    <span className="content-icon">
                                        <span className="fa-stack fa-2x">
                                            <FaRegEye className="fa-stack-1x" />
                                        </span>
                                    </span>
                                    <p className="content-text">{projeto.title}</p>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </Container>
        </div>
    );
}