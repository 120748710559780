import React from 'react';
import { Link, useHistory, useLocation, NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaBars } from 'react-icons/fa';

import logoHome from './assets/logo_home.png';

function Header() {

    return (
        <header>
                <Navbar collapseOnSelect expand="lg">
                <div className="navbar-pattern">
                    <table>
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Container>
                    <Navbar.Brand><Link to="/" ><img alt="Pulcher Studio" className="logo" src={logoHome} /></Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav">
                            <FaBars/>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                        </Nav>
                        <Nav>
                            <div className="nav_pre_a" ><Nav.Link as={NavLink} exact to="/" activeClassName="active" className="home" >HOME</Nav.Link></div>
                            <div className="nav_pre_a" ><Nav.Link as={NavLink} exact to="/empresa" activeClassName="active" className="empresa" >A EMPRESA</Nav.Link></div>
                            <div className="nav_pre_a" ><Nav.Link as={NavLink} exact to="/projetos" activeClassName="active" className="projetos" >PROJETOS</Nav.Link></div>
                            <div className="nav_pre_a" ><Nav.Link as={NavLink} exact to="/clientes" activeClassName="active" className="clientes" >CLIENTES</Nav.Link></div>
                            <div className="nav_pre_a" ><Nav.Link as={NavLink} exact to="/contato" activeClassName="active" className="contato" >CONTATO</Nav.Link></div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Container>

            </Container>
	</header>
    );
}

export default Header;
