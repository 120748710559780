import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col'

import './styles.css';

export default function Empresa(){

    const [ideiaativa, setIdeiaAtiva] = useState({activeLink: "1"});    

    function ideia(e){

        console.log(e.target.getAttribute("data-key"));
        console.log(this);
        console.log('::::'+e.target.getAttribute("data-key"));
        setIdeiaAtiva({ activeLink: e.target.getAttribute("data-key") });

        console.log("ideiaativa: " +  ideiaativa.activeLink);
        console.log("ideiaativa123: " +  e.propx);
    
    }

    return (
        <div className="container-empresa">
            <Container>
                <h1 className="ps-page-title-text"><span className="bar"></span>A EMPRESA<span className="bar"></span></h1>
            </Container>
            <Container fluid className="second-row" >
                <Container className="text-center ideias_titulo">
                    <h4>Oferecemos ideias inovadoras e <span>criativas</span></h4>
                    <div className="click-menu">
                        <div className="line"></div>
                        <table>
                            <tbody>
                                <tr>
                                    <td><a key={1} onClick={ideia} data-key="1" className={ideiaativa.activeLink == 1 ? 'click-img click-img-1 active' : 'click-img click-img-1'} ></a></td>
                                    <td><a key="2" onClick={ideia} data-key="2" className={ideiaativa.activeLink == 2 ? 'click-img click-img-2 active' : 'click-img click-img-2'} ></a></td>
                                    <td><a key="3" onClick={ideia} data-key="3" className={ideiaativa.activeLink == 3 ? 'click-img click-img-3 active' : 'click-img click-img-3'} ></a></td>
                                    <td><a key="4" onClick={ideia} data-key="4" className={ideiaativa.activeLink == 4 ? 'click-img click-img-4 active' : 'click-img click-img-4'} ></a></td>

                                </tr>
                            </tbody>
                        </table>
                    </div>                        
                    <div className="click-text-wrapper">
                        <div className={ideiaativa.activeLink == 1 ? 'click-text click-text-1 active' : 'click-text click-text-1'}><em>Pulcher</em> vem do latim “belo”, esse é o espíritio da Pulcher Studio. Criamos e desenvolvemos projetos priorizando três pilares: Qualidade, Praticidade e Beleza.</div>
                        <div className={ideiaativa.activeLink == 2 ? 'click-text click-text-2 active' : 'click-text click-text-2'}>Somos especializados em identidades visuais, papelaria administrativa, apps, projetos web e material de apoio.</div>
                        <div className={ideiaativa.activeLink == 3 ? 'click-text click-text-3 active' : 'click-text click-text-3'}>Temos uma equipe jovem, competente e profissional pronta para alcançarmos juntos as melhores soluções para nossos clientes.</div>
                        <div className={ideiaativa.activeLink == 4 ? 'click-text click-text-4 active' : 'click-text click-text-4'}>Estamos sempre inovando para alcançar nossos objetivos e fazer com que nossos clientes tenham sucesso em suas campanhas.</div>
                    </div>
                </Container>
            </Container>

            <Container className="servicos" >
                <h1 className="ps-page-title-text"><span className="bar"></span>SERVIÇOS<span className="bar"></span></h1>
            </Container>
            <Container className="servicos" >
                <ul className="lista_servicos">
                        <li className="servico col-md-4 col-sm-6 col-12">
                        <div className="row">
                            <Col xs={3}>
                                <img src="https://www.pulcherstudio.com/wp-content/uploads/2017/05/servicos-1.png" alt="" />
                            </Col>
                            <Col xs={9}>
                                A confecção da identidade visual, principalmente logotipo capaz de representar a assinatura institucional da empresa e o conjunto de elementos que a representam visualmente, e de forma sistematizada o cliente.
                            </Col>
                        </div>
                        </li>
                        <li className="servico col-md-4 col-sm-6 col-12">
                        <div className="row">
                            <Col xs={3}>
                                <img src="https://www.pulcherstudio.com/wp-content/uploads/2017/05/servicos-2.png" alt="" />
                            </Col>
                            <Col xs={9}>
                                Peças de papelaria empresariais, cartão de visitas, papel timbrado, envelopes, blocos, cadernos, agendas, recibos, pastas, receituários, entre outros.
                            </Col>
                        </div>
                        </li>
                        <li className="servico col-md-4 col-sm-6 col-12">
                        <div className="row">
                            <Col xs={3}>
                                <img src="https://www.pulcherstudio.com/wp-content/uploads/2017/05/servicos-3.png" alt="" />
                            </Col>
                            <Col xs={9}>
                                Principal peça de comunicação de marketing das pequenas, médias e grandes empresas em formatos variados entre eles flyers, folders, folhetos, encartes, banners, cartazes, revistas, postais, jornais, imãs e cardápios.
                            </Col>
                        </div>
                        </li>
                        <li className="servico col-md-4 col-sm-6 col-12">
                        <div className="row">
                            <Col xs={3}>
                                <img src="https://www.pulcherstudio.com/wp-content/uploads/2017/05/servicos-4.png" alt="" />
                            </Col>
                            <Col xs={9}>
                                Criação, desenvolvimento e programação de sites, suporte para hospedagem, manutenção e registro de domínio.
                            </Col>
                        </div>
                        </li>
                        <li className="servico col-md-4 col-sm-6 col-12">
                        <div className="row">
                            <Col xs={3}>
                                <img src="https://www.pulcherstudio.com/wp-content/uploads/2017/05/servicos-5.png" alt="" />
                            </Col>
                            <Col xs={9}>
                                Apresentações digitais para empresas, produtos e serviços. Criação de encartes e capas para de CD's e DVD's.
                            </Col>
                        </div>
                        </li>
                        <li className="servico col-md-4 col-sm-6 col-12">
                        <div className="row">
                            <Col xs={3}>
                                <img src="https://www.pulcherstudio.com/wp-content/uploads/2017/05/servicos-6.png" alt="" />
                            </Col>
                            <Col xs={9}>
                                Gestão de dados dos clientes, fornecedores e outros públicos. Voltado para ações eficazes de relacionamento e fidelização.
                            </Col>
                        </div>
                        </li>
                        <li className="servico col-md-4 col-sm-6 col-12">
                        <div className="row">
                            <Col xs={3}>
                                <img src="https://www.pulcherstudio.com/wp-content/uploads/2017/05/servicos-7.png" alt="" />
                            </Col>
                            <Col xs={9}>
                                Realização de diversos serviços gráficos, entre eles: Gigantografia, plotagem, adesivos, placas informativas, lonas, letreiros.
                            </Col>
                        </div>
                        </li>
                        <li className="servico col-md-4 col-sm-6 col-12">
                        <div className="row">
                            <Col xs={3}>
                                <img src="https://www.pulcherstudio.com/wp-content/uploads/2017/05/servicos-8.png" alt="" />
                            </Col>
                            <Col xs={9}>
                                Gestão, criação e planejamento de redes sociais como Facebook, Instagram, Twitter, Youtube, Linkedin.
                            </Col>
                        </div>
                        </li>
                        <li className="servico col-md-4 col-sm-6 col-12">
                            <div className="row">
                                <Col xs={3}>
                                    <img src="https://www.pulcherstudio.com/wp-content/uploads/2017/05/servicos-9.png" alt="" />
                                </Col>
                                <Col xs={9}>
                                    Realização de diversos complementos para seu negócio, como: Uniformes, comandas, camisas promocionais, brindes coorporativos, jogo americano e outros.
                                </Col>
                            </div>
                        </li>
                </ul>
            </Container>
        </div>
    );
}