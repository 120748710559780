import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import './styles.css';

export default function Home(){

    return(
        <div className="container-home">
            <Container fluid className="home_ola">
                <h1 className="ps-page-title-text"><span className="bar"></span>PROJETOS<span className="bar"></span></h1>
            </Container>

            <Container>
                <h1 className="ps-page-title-text"><span className="bar"></span>PROJETOS<span className="bar"></span></h1>
            </Container>
        </div>
    );
}