import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FaFacebookSquare, FaWhatsapp, FaEnvelope, FaPhone, FaMapMarkerAlt, FaInstagram } from 'react-icons/fa';

function Footer() {

    return (
        <footer>
            <div className="footer-pattern">
                <div className="footer-pattern-wrapper">
                    <div className="footer-pattern-cell"></div>
                    <div className="footer-pattern-cell">
                        <div className="pattern-image"></div>
                    </div>
                </div>
            </div>
                <Container>
                <Row className="row-info">
                    <Col sm={"auto"} xs={12} className="div-footer col-custom-local">
                        <h5>LOCAL</h5>
                        <table>
                            <tbody>
                                <tr>
                                    <td><FaMapMarkerAlt className="icon" /></td>
                                    <td>Av. Presidente Vargas, 844 - 17º andar</td>
                                </tr>
                            </tbody>
                        </table>
                        <h5>MÍDIAS SOCIAIS</h5>
                        <a href="https://www.facebook.com/PulcherStudio/" target="_blank" className="footer-social-link">
                            <FaFacebookSquare className="icon" />
                        </a>
                        <a href="https://www.instagram.com/pulcherstudio/" target="_blank" className="footer-social-link">
                            <FaInstagram className="icon" />
                        </a>
                    </Col>
                    <Col sm={"auto"} xs={12} className="div-footer col-custom-address">
                        <h5>CONTATO</h5>
                        <table>
                            <tbody>
                                <tr>
                                    <td><FaEnvelope className="icon" /></td>
                                    <td>contato@pulcherstudio.com</td>
                                </tr>
                            </tbody>
                        </table>
                        <table>
                            <tbody>
                                <tr>
                                    <td><FaPhone className="icon" /></td>
                                    <td>+55 (21) 3473-2696</td>
                                </tr>
                            </tbody>
                        </table>
                        <table>
                            <tbody>
                                <tr>
                                    <td><FaWhatsapp className="icon" /></td>
                                    <td><a href="https://api.whatsapp.com/send?phone=5521996556662">+55 (21) 99655-6662</a></td>
							    </tr>
						    </tbody>
					    </table>
				    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <p className="all-rights">© 2012 - {new Date().getFullYear()} | Pulcher Studio · Todos os direitos reservados</p>
                    </Col>
                </Row>
                </Container>
	</footer>
    );
}

export default Footer;
