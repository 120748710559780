import React, { useState, Component } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaWhatsapp } from 'react-icons/fa';

import './styles.css';

export default function Contato(){
    const [InputNome, setInputNome] = useState({ className_label: '', value: '' });  
    const [InputEmail, setInputEmail] = useState({ className_label: '', value: '' });  
    const [InputTel, setInputTel] = useState({ className_label: '', value: '' });  
    const [InputAssunto, setInputAssunto] = useState({ className_label: '', value: '' });  
    const [InputMsg, setInputMsg] = useState({ className_label: '', value: '' });  

    function handleInputFocus(e) {
        console.log(e.target.name);
        console.log(e.target.value);
        switch (e.target.name) {
            case 'nome':
                setInputNome({ className_label: 'input_ativo', value: e.target.value });
                break;
            case 'email':
                setInputEmail({ className_label: 'input_ativo', value: e.target.value });
                break;
            case 'tel':
                setInputTel({ className_label: 'input_ativo', value: e.target.value });
                break;
            case 'assunto':
                setInputAssunto({ className_label: 'input_ativo', value: e.target.value });
                break;
            case 'msg':
                setInputMsg({ className_label: 'input_ativo', value: e.target.value });
                break;

            default:
                break;
        }
    };

    function handleInputBlur(e) {
        console.log(e);
        console.log(e.target.name);
        console.log(e.target.value.length);
        if (e.target.value.length < 1){
            switch (e.target.name) {
                case 'nome':
                    setInputNome({ className_label: '', value: e.target.value });
                    break;
                case 'email':
                    setInputEmail({ className_label: '', value: e.target.value });
                    break;
                case 'tel':
                    setInputTel({ className_label: '', value: e.target.value });
                    break;
                case 'assunto':
                    setInputAssunto({ className_label: '', value: e.target.value });
                    break;
                case 'msg':
                    setInputMsg({ className_label: '', value: e.target.value });
                    console.log('remve');
                    break;
            
                default:
                    break;
            }
        }
    };

    return (
        <div className="container-contato" >
            <Container>
                <h1 className="ps-page-title-text"><span className="bar"></span>CONTATO<span className="bar"></span></h1>
            </Container>
            <div className="contato-map">
                <iframe className="google_maps" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14701.19068017041!2d-43.183718!3d-22.902389!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9434cf06805d07c4!2sPulcher%20Studio!5e0!3m2!1spt-BR!2sbr!4v1586122541647!5m2!1spt-BR!2sbr" width="100%" height="300" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
            <Container>
                <Row>
                    <Col xs={12} sm={6}>
                            <h4>Fale conosco:</h4>
                            <div role="form" className="" id="" lang="pt-BR" dir="ltr">                               
                                <form action="/contato/" method="post" className="" novalidate="novalidate">
          
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="ps-input-group">
                                                <span className={InputNome.className_label}>
                                                <span className="your-name">
                                                    <input value={InputNome.value} onChange={e => setInputNome({ className_label: 'input_ativo', value: e.target.value})} onFocus={handleInputFocus} onBlur={handleInputBlur} type="text" name="nome" size="40"  className='ps-form-input' aria-required="true" aria-invalid="false" />
                                                </span>
                                                <label className="label" ><span>Nome</span></label>
                                            </span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="ps-input-group">
                                                <span className={InputEmail.className_label}>
                                                <span className="your-email">
                                                    <input value={InputEmail.value} onChange={e => setInputEmail({ className_label: 'input_ativo', value: e.target.value })} onFocus={handleInputFocus} onBlur={handleInputBlur} type="email" name="email" size="40" className="ps-form-input" aria-required="true" aria-invalid="false" />
                                                </span>
                                                <label className="label"><span>Email</span></label>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="ps-input-group">
                                                <span className={InputTel.className_label}>
                                                <span className="your-tel">
                                                    <input value={InputTel.value} onChange={e => setInputTel({ className_label: 'input_ativo', value: e.target.value })} onFocus={handleInputFocus} onBlur={handleInputBlur} type="tel" name="tel" size="40" className="ps-form-input" aria-invalid="false" maxlength="15" />
                                                </span>
                                                <label className="label"><span>Telefone</span> <span className="opcional">(Opcional)</span></label>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="ps-input-group">
                                                <span className={InputAssunto.className_label}>
                                                    <span className="your-tel">
                                                    <select name="assunto" id="" className="ps-form-input ps-select" onChange={e => setInputAssunto({ className_label: 'input_ativo', value: e.target.value })} onFocus={handleInputFocus} onBlur={handleInputBlur}>
                                                            <option value=""></option>
                                                            <option value="1">Orçamento/Vendas</option>
                                                            <option value="2">Financeiro</option>
                                                            <option value="3">Parcerias</option>
                                                            <option value="4">Outros</option>
                                                        </select>
                                                    </span>
                                                    <label className="label"><span>Assunto</span></label>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="ps-input-group">
                                                <span className={InputMsg.className_label}>
                                                <span className="form_msg"><textarea className="ps-form-input ps-select" onChange={e => setInputMsg({ className_label: 'input_ativo', value: e.target.value })} onFocus={handleInputFocus} onBlur={handleInputBlur} name="msg" cols="40" rows="10" className="ps-form-input" aria-invalid="false"></textarea></span>
                                                <label className="label"><span>Mensagem</span></label>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <input type="submit" value="Enviar" className="ps-form-submit" /><span className="ajax-loader"></span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <h4>Endereço:</h4>
                        <table>
                            <tr>
                                <td><FaMapMarkerAlt className="icon" /></td>
                                <td><a href="" >Av. Presidente Vargas, 844 - 17&ordm; andar<br/>Centro - Rio de Janeiro, RJ | CEP: 20071-001</a></td>
                            </tr>
                        </table>
                        <h4>Contato:</h4>
                        <table>
                            <tr>
                                <td><FaPhone className="icon" /></td>
                                <td>+55 (21) 3473-2696</td>
                            </tr>
                            <tr>
                                <td><FaWhatsapp className="icon" /></td>
                                <td><a href="https://api.whatsapp.com/send?phone=5521996556662" target="_blank">+55 (21) 99655-6662</a></td>
                            </tr>
                            <tr>
                                <td><FaEnvelope className="icon" /></td>
                                <td>contato@pulcherstudio.com</td>
                            </tr>
                        </table>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}